import { PortableTextTypeComponentProps } from "@portabletext/react";
import Accordion from "components/UI/Accordion";
import PortableText from "components/PortableText/PortableText";
import { useRouter } from "next/router";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports
import { Palette } from "@mui/material/styles/createPalette";
import { RealtimeGuidelinesType, useGetHDIRGuidelines } from "data/contentData/hooks/hdir.hooks";
type PropsType = PortableTextTypeComponentProps<RealtimeGuidelinesType>;
export default function RealtimeGuideline({
  value
}: PropsType) {
  const router = useRouter();
  const {
    data
  } = useGetHDIRGuidelines(value.id, router.asPath);
  const {
    content,
    source
  } = data ? {
    content: data.blockContent ? <PortableText value={JSON.parse(data.blockContent)} /> : null,
    source: data.source
  } : {
    content: <RealtimeGuidelineSkeleton />,
    source: <Skeleton />
  };
  const theme = useTheme();
  const {
    summary,
    details
  } = theme.palette.notes[value.style as keyof Palette["notes"] || {}];
  return <Accordion collapsed={value.collapsed} header={value.title} id={value.id} level="h4" margin={theme.spacing(1, 2)} summaryBackground={summary.main} summaryHoverBackground={summary.hover} background={details} data-sentry-element="Accordion" data-sentry-component="RealtimeGuideline" data-sentry-source-file="RealtimeGuideline.tsx">
            <ContentWrapper data-sentry-element="ContentWrapper" data-sentry-source-file="RealtimeGuideline.tsx">{content}</ContentWrapper>
            <Source variant="body2" align="right" data-sentry-element="Source" data-sentry-source-file="RealtimeGuideline.tsx">
                {source}
            </Source>
        </Accordion>;
}
function RealtimeGuidelineSkeleton() {
  return <>
            <Skeleton height={24} data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <br />
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <br />
        </>;
}
const ContentWrapper = styled("div")(({
  theme
}) => ({
  padding: theme.spacing(1, 2)
}));
const Source = styled(Typography)(({
  theme
}) => ({
  fontWeight: 700,
  margin: theme.spacing(2),
  padding: theme.spacing(1)
}));